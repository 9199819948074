/**
 * Composable to manage modal overflow.
 * Purpose: Prevent custom dropdowns (e.g., multi-select) from being cut off by the modal's overflow styles.
 * By default, overflow is disabled and temporarily enabled when a dropdown opens.
 */

const modalOverflowVisible = ref(false);

export const useModalOverflow = () => {
    const setModalOverflowVisible = (value: boolean) => {
        modalOverflowVisible.value = value;
    };

    return {
        modalOverflowVisible,
        setModalOverflowVisible,
    };
};
